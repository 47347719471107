import { useState } from 'react';
import YouTube from 'react-youtube';

export default function Video({ videoId, title }) {
    const [isPlaying, setIsPlaying] = useState(false);

    const handleReady = (event) => {
        console.log('YouTube Player is ready');
    };

    const handleStateChange = (event) => {
        const playerState = event.data;

        if (playerState === 1) {
            setIsPlaying(true); // Hide overlay
        } else if (playerState === -1) {
            console.log('Showing overlay 2 seconds before video ends');
            setIsPlaying(false); // Show overlay
        }
    };

    const opts = {
        height: '315',
        width: '560',
        playerVars: {
            autoplay: 1,
            mute: 1,
            loop: 1,
            playlist: videoId,
            controls: 0,
            modestbranding: 1,
        },
    };

    return (
        <>
            {/* Overlay */}
            <div className={`video-cover ${isPlaying ? '' : 'show'}`}></div>

            {/* YouTube Player */}
            <YouTube
                videoId={videoId}
                opts={opts}
                onReady={handleReady}
                onStateChange={handleStateChange}
            />
        </>
    );
}
