import { useEffect } from 'react';

export default function GlassCursorEffect() {
  useEffect(() => {
    // Create the custom glass cursor element
    const cursor = document.createElement('div');
    cursor.classList.add('glass-cursor');
    document.body.appendChild(cursor);

    // Track mouse movement and update the cursor position
    const moveCursor = (e) => {
      const cursorSize = 150; // size of the glass cursor
      const halfSize = cursorSize / 2;
      cursor.style.transform = `translate(${e.clientX - halfSize}px, ${e.clientY - halfSize}px)`;
    };

    // Function to add magnification effect
    const addMagnifyEffect = (e) => {
      const target = e.target;

      // Add scaling if it's a magnifiable element
      if (target.classList.contains('magnify')) {
        target.style.transform = 'scale(1.05)';  // Slightly magnify
        target.style.transition = 'transform 0.3s ease'; // Smooth transition
      }
    };

    // Function to remove magnification effect
    const removeMagnifyEffect = (e) => {
      const target = e.target;

      // Remove the scaling if the element was magnified
      if (target.classList.contains('magnify')) {
        target.style.transform = 'scale(1)'; // Reset scale
      }
    };

    // Event listener for mouse movement
    window.addEventListener('mousemove', moveCursor);

    // Event listeners for hover to add/remove magnification
    document.addEventListener('mouseover', addMagnifyEffect);
    document.addEventListener('mouseout', removeMagnifyEffect);

    // Clean up on unmount
    return () => {
      window.removeEventListener('mousemove', moveCursor);
      document.removeEventListener('mouseover', addMagnifyEffect);
      document.removeEventListener('mouseout', removeMagnifyEffect);
      document.body.removeChild(cursor);
    };
  }, []);

  return null; // No visible component, the glass cursor is added dynamically
}
