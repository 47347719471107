import Marquee from 'react-fast-marquee';

export default function ScrollingText() {
    return (
        <Marquee>
            <p> we do______<span>DIGITAL</span> </p>
            <p> we do______<span>COMMUNITY</span> </p>
            <p> we do______<span>SEO</span> </p>
            <p> we do______<span>CONTENT</span> </p>
            <p> we do______<span>WEB</span> </p>
            <p> we do______<span>STRATEGY</span> </p>
        </Marquee>
    )
}
